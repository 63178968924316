import { ModelSelect, BasicSelect } from 'vue-search-select';
export default {
  name: 'CreateBill',
  components: {
    ModelSelect,
    BasicSelect
  },
  data() {
    return {
      showCreateBillModal: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: null,
      loader: false,
      editMode: false,
      defaultSelect: {
        value: null,
        text: null
      },
      defaultList: [],
      billingList: [],
      billingTableColums: [
        {
          key: 'edit'
        },
        {
          key: 'source'
        },
        {
          key: 'request'
        },
        {
          key: 'template'
        },
        {
          key: 'bill_num'
        },
        {
          key: 'bill_status'
        },
        {
          key: 'bill_cycle'
        },
        {
          key: 'customer'
        },
        {
          key: 'site'
        },
        {
          key: 'project'
        },
        {
          key: 'sector'
        },
        {
          key: 'pocket'
        },
        {
          key: 'tower'
        }
      ]
    };
  },
  mounted() {
    this.eventBus.$on('createBillActions', actionName => {
      if (actionName === 'add') {
        this.showCreateBillModal = true;
      }
    });
  },
  methods: {
    hideModel() {},
    rowSelected() {}
  }
};
