import store from '../../../../../../store';
export default {
  name: 'BillingDetails',
  props: ['billHdrId'],
  data() {
    return {
      loader: false,
      compositeCheck: false,
      sourceList: [],
      selectedSource: {
        value: null,
        text: null
      },
      currencyList: [],
      selectedCurrency: {
        value: null,
        text: null
      },
      billingCycleList: [],
      selectedBillingCycle: {
        value: null,
        text: null
      },
      billRequestList: [],
      selectedBillRequest: {
        value: 0,
        text: null
      },
      billTemplateList: [],
      selectedBillTemplate: {
        value: 0,
        text: null
      },
      billDetailsData: {},
      invoiceBillList: [],
      invoiceBillFields: [
        {
          key: 'component_group_vset',
          label: 'Comp Group'
        },
        {
          key: 'component_display_name',
          label: 'Comp Name'
        },
        {
          key: 'transaction_amt',
          label: 'Amount'
        },
        {
          key: 'value_date'
        },

        {
          key: 'gl_date'
        },
        {
          key: 'tax_category'
        },
        {
          key: 'tax_amount'
        }
      ],
      accountingList: [],
      accountingFields: [
        {
          key: 'line_type'
        },
        {
          key: 'ccid',
          label: 'CCID'
        },
        {
          key: 'gl_date'
        },
        {
          key: 'period'
        },
        {
          key: 'trxn_amt_dr'
        },
        {
          key: 'trxn_amt_cr'
        },
        {
          key: 'amt_dr'
        },
        {
          key: 'amt_cr'
        },
        {
          key: 'acc_status'
        },
        {
          key: 'posting_status'
        }
      ],
      outStandingList: [],
      outStandingFeilds: [
        {
          key: 'line_type'
        },
        {
          key: 'document_num',
          label: 'Bill No'
        },
        {
          key: 'value_date'
        },
        {
          key: 'comp_group',
          label: 'Comp Group'
        },
        {
          key: 'comp',
          label: 'Component'
        },
        {
          key: 'tax'
        },
        {
          key: 'bill_amount',
          label: 'Bill Amt',
          class: 'text-right'
        },
        {
          key: 'bill_comp_id',
          class: 'd-none'
        },
        {
          key: 'bill_tax_id',
          class: 'd-none'
        },
        {
          key: 'applied_amt',
          label: 'App Amt',
          class: 'text-right'
        },
        {
          key: 'os_amount',
          label: 'OS Amt',
          class: 'text-right'
        }
      ],
      distributionDetails: {},
      distributionList: [],
      distributionFeilds: [
        {
          key: 'charge_type'
        },
        {
          key: 'ccid',
          label: 'CCID'
        },
        {
          key: 'gl_date'
        },
        {
          key: 'period'
        },
        {
          key: 'accounting_status',
          label: 'Accounting'
        },
        {
          key: 'posting_status',
          label: 'Posting'
        },
        {
          key: 'trx_amount_dr',
          label: 'Trx Amt DR'
        },
        {
          key: 'trx_amount_cr',
          label: 'Trx Amt CR'
        },
        {
          key: 'amount_dr'
        },
        {
          key: 'amount_cr'
        },
        {
          key: 'narration'
        }
      ],
      totalBillAmount: null
    };
  },
  mounted() {
    if (this.billHdrId) {
      this.getBillDetails(this.billHdrId);
    }
    // this.eventBus.$on('getBillDetails', billDetails => {
    //   this.billHdrId = billDetails.bill_hdr_id;
    //   this.getBillDetails(billDetails.bill_hdr_id);
    // });
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'upload') {
          this.eventBus.$emit('commonUpload', { id: this.billHdrId });
        }
      }
    });
  },
  methods: {
    getBillDetails(billHdrId) {
      this.loader = true;
      this.$store
        .dispatch('fms/getFMSBillingDetail', billHdrId)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.billDetailsData = response.data.data;
            this.billDetailsData.unitType = this.billDetailsData.with_sub_unit
              ? 'With'
              : 'Without';
            this.invoiceBillList = response.data.data.bill_details;
            if (this.billDetailsData.source_hdr_id) {
              this.getSourceList();
            }
            if (this.billDetailsData.billing_cycle_hdr_id) {
              this.getBillingCycle();
            }
            if (this.billDetailsData.request_id) {
              this.getFmsBillRequest();
            }
            if (this.billDetailsData.template_id) {
              this.getFmsBillTemplate();
            }
            if (this.billDetailsData.transactional_currency_id) {
              this.getCurrencyById();
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getBillOutstanding() {
      const payload = {
        bill_hdr_id: this.billHdrId
      };
      this.$store
        .dispatch('fms/getFMSBillOutStanding', payload)
        .then(response => {
          if (response.status === 200) {
            const result = response.data.data.fms_outstandings;
            this.outStandingList = result;
            const totalOSAmount = result.reduce((totalValue, currentValue) => {
              return totalValue + currentValue.os_amount;
            }, 0);
            this.totalBillAmount = result.reduce((totalValue, currentValue) => {
              return (
                totalValue + (currentValue.os_amount + currentValue.applied_amt)
              );
            }, 0);
            const dummyRow = {
              adj_amount: 0,
              applied_amt: 0,
              bill_comp_id: 0,
              bill_hdr_id: 0,
              bill_tax_id: null,
              comp: null,
              comp_group: null,
              comp_group_vset: null,
              comp_vset: null,
              document_num: null,
              fms_receipt_adj_id: null,
              line_type: null,
              os_amount: totalOSAmount,
              tax: 'Total',
              transaction_amt: null,
              trx_line_id: 0,
              value_date: null
            };
            this.outStandingList.push(dummyRow);
          }
        });
    },
    getDistribution() {
      const payload = {
        bill_hdr_id: this.billHdrId,
        composite: false
      };
      this.$store.dispatch('fms/getFMSDistribution', payload).then(response => {
        if (response.status === 200) {
          this.distributionDetails = response.data.data;
          this.distributionList = response.data.data.trx_distributions;
        }
      });
    },
    getSourceList() {
      this.$store.dispatch('fms/getSource').then(response => {
        if (response.status === 200) {
          const result = response.data.data;
          const valueTypeSet = result.map(type => {
            return {
              value: type.source_hdr_id,
              text: type.source_name
            };
          });
          this.sourceList = valueTypeSet;
          const findBillSource = valueTypeSet.find(
            source => source.value === this.billDetailsData.source_hdr_id
          );
          if (findBillSource) {
            this.selectedSource = findBillSource;
          }
        }
      });
    },
    getBillingCycle() {
      this.$store.dispatch('fms/getBillingCycle').then(response => {
        if (response.status === 200) {
          const result = response.data.data;
          const valueTypeSet = result.map(type => {
            return {
              value: type.billing_cycle_hdr_id,
              text: type.billing_cycle_name
            };
          });
          this.billingCycleList = valueTypeSet;
          this.selectedBillingCycle =
            valueTypeSet && valueTypeSet.length === 1
              ? valueTypeSet
              : this.billingCycleList;
          const findBillCycleByHdrId = valueTypeSet.find(
            billCycle =>
              billCycle.value === this.billDetailsData.billing_cycle_hdr_id
          );
          if (findBillCycleByHdrId) {
            this.selectedBillingCycle = findBillCycleByHdrId;
          }
        }
      });
    },
    getFmsBillRequest() {
      const payload = {
        menu_id: store.state.shared.menuId
      };
      this.$store
        .dispatch('fms/getFmsBillRequest', payload)
        .then(response => {
          if (response.status === 200) {
            const result = response.data.data;
            const valueTypeSet = result.map(type => {
              return {
                value: type.request_id,
                text: type.request_name
              };
            });
            this.billRequestList = valueTypeSet;
            const findBillRequestById = valueTypeSet.find(
              request => request.value === this.billDetailsData.request_id
            );
            if (findBillRequestById) {
              this.selectedBillRequest = findBillRequestById;
            }
          }
        })
        .catch(() => {});
    },
    getFmsBillTemplate(requestId) {
      const payload = {
        request_id: requestId
      };
      this.$store
        .dispatch('fms/getFMSBillingTemplate', payload)
        .then(response => {
          if (response.status === 200) {
            const result = response.data.data;
            const valueTypeSet = result.map(type => {
              return {
                value: type.template_id,
                text: type.template_name
              };
            });
            this.billTemplateList = valueTypeSet;
            const findTemplateById = valueTypeSet.find(
              template => template.value === this.billDetailsData.template_id
            );
            if (findTemplateById) {
              this.selectedBillTemplate = findTemplateById;
            }
          }
        });
    },
    getCurrencyById() {
      this.$store
        .dispatch(
          'currency/getCurrencyById',
          this.billDetailsData.transactional_currency_id
        )
        .then(response => {
          if (response.status === 200) {
            const result = response.data.data;
            this.selectedCurrency.value = result.currency_id;
            this.selectedCurrency.text = result.currency_code;
          }
        });
    }
  },
  destroyed() {
    this.unsubscribe();
    this.eventBus.$off('getBillDetails');
  }
};
